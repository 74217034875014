import React from 'react';
import i18nInstance from "../i18n";
import moment from 'moment-timezone';
import get from 'lodash/get';
import memoize from 'fast-memoize';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
    getEnvData,
    getCurrencySymbol,
    sendGoogleAnalyticsEvent,
    getUpdatedRequestStartDate,
    getMapDirectionsUrl
} from 'helpers';
import DriverGrayIcon from 'images/icons/ico_mydriver_gray.svg';
import VehicleGrayIcon from 'images/icons/ico_licensePlate_gray.svg';
import Plane from 'images/icons/ico_plane.svg';
import PlaneGray from 'images/icons/ico_plane-gray.svg';
import PlaneWhite from 'images/icons/plane-white.svg';
import Passengers from 'images/icons/passengers.svg';
import Seats from 'images/icons/seats.svg';
import Distance from 'images/icons/distance.svg';
import Duration from 'images/icons/duration.svg';
import PickUpDrop from 'images/icons/ico_pickup_drop.svg';
import Suitcase from 'images/icons/suitcase.svg';
import CarClock from 'images/icons/carClock.svg';
import ARRIVED from 'images/icons/arrived.svg';
import STARTED from 'images/icons/started.svg';
import COMPLETED from 'images/icons/completed.svg';
import LATE from 'images/icons/late.svg';
import PROFILE from 'images/icons/ico_profile_gray.svg';
import COMMENT from 'images/icons/ico_comment_gray.svg';
import GreenCar from 'images/icons/ico_car_green.svg';
import OrangeCar from 'images/icons/ico_car_orange.svg';
import RedCar from 'images/icons/ico_car_red.svg';
import WarningRed from 'images/icons/ico_warning_red.svg';

/*
 *   This file contains cell-wise grouped table components which must
 *   be reused across different tables
*/

// * DATE, TIME
export function dateTimeCell(date) {
    if (!date) {
        return <></>;
    }
    const t = i18nInstance.t.bind(i18nInstance);
    const rideDate = moment(date).calendar(null, {
        sameDay: `[${t("TODAY")}]`,
        nextDay: `[${t("TOMORROW")}]`,
        lastDay: `[${t("YESTERDAY")}]`,
        nextWeek: `DD/MM/YYYY`,
        lastWeek: `DD/MM/YYYY`,
        sameElse: 'DD/MM/YYYY'
    });
    const rideTime = moment(date).format("HH:mm");

    return (<div className="date-time">{rideTime}, {rideDate}</div>);
}

// * DATE, TIME & STATUS COLUMN
export function dateTimeStatusCell({ row = {}, showStatus = true }) {

    const t = i18nInstance.t.bind(i18nInstance);
    const ridIcons = {
        "ARRIVED": ARRIVED,
        "STARTED": STARTED,
        "COMPLETED": COMPLETED,
        "LATE": LATE
    };
    const cars = {
        "PERFECT": GreenCar,
        "AVERAGE": OrangeCar,
        "POOR": RedCar,
    };
    const kpiTypes = {
        "ride_confirmation_card_pressed": "RIDE_NOT_CONFIRMED_ON_TIME",
        "pre_ride_tracking": "APP_NOT_KEPT_OPEN_ENROUTE_PICKUP",
        "arrived_within_pickup": "NOT_ARRIVED_ON_TIME_AT_PICKUP",
        "arrived_outside_pickup": "NOT_ARRIVED_ON_TIME_AT_PICKUP",
        "on_ride_tracking": "APP_NOT_KEPT_OPEN_DURING_RIDE",
        "ride_completed_on_time": "RIDE_NOT_COMPLETED_ON_TIME"
    };

    const {
        status = "",
        tags = [],
        buffer_time = 0,
        flight_number,
        transportation_details = {},
        score,
        missed_kpis = [],
    } = row || {};

    const showMissedKpiAndScore = score || missed_kpis.length;

    const latestReservationDate = getUpdatedRequestStartDate(row);
    const rideDate = moment(latestReservationDate).calendar(null, {
        sameDay: `[${t("TODAY")}]`,
        nextDay: `[${t("TOMORROW")}]`,
        lastDay: `[${t("YESTERDAY")}]`,
        nextWeek: `DD/MM/YYYY`,
        lastWeek: `DD/MM/YYYY`,
        sameElse: 'DD/MM/YYYY'
    });
    const rideTime = moment(latestReservationDate).format("HH:mm");

    let rideStatus = ["ARRIVED", "STARTED", "COMPLETED", "LATE"].includes(status.toUpperCase())
        ? status : "";

    if (tags.length > 0) {
        rideStatus = ['HOT RIDE', 'FIXED PRICE RIDE'].includes(tags[0].toUpperCase()) ? '' : tags[0].toUpperCase();
    }

    const { terminal, status: flightStatus = "", last_updated_at } = get(transportation_details, "tracking_status", {}) || {};
    const flightArrivalTime = moment(latestReservationDate).subtract(buffer_time, "minutes").format("HH:mm");

    return (
        <div className="date-time-status">
            <div className="time">{rideDate}, {rideTime}</div>
            {showStatus && (
                <div className="status-container">
                    {flight_number && (
                        <OverlayTrigger
                            placement="top"
                            /* delayShow={300}
                            delayHide={1500000} */
                            onEntered={() => {
                                sendGoogleAnalyticsEvent("flight", {
                                    event_category: "manage_rides",
                                    event_label: "hover"
                                });
                            }}
                            overlay={
                                <Tooltip id="flight-tooltip">
                                    <div className="pick-up">
                                        <div className="time-info">
                                            <img src={CarClock} alt="car" className="icon" />
                                            {rideTime} | {rideDate}
                                        </div>
                                        <div className="pickup-comment">
                                            {t("PICK_UP_MINS_AFTER_LANDING", { "minutes": buffer_time })}
                                        </div>
                                    </div>

                                    <div className="flight">
                                        <img src={PlaneWhite} alt="plane" className="icon" />
                                        {`${flight_number} `}
                                        <span className={`flight-time-status ${flightStatus.toUpperCase()}`}>
                                            {flightStatus ? `(${flightStatus})` : ""}
                                        </span>
                                        <span className="time">{flightArrivalTime}</span>
                                        {terminal ? (
                                            <div className="terminal">
                                                {t("TERMINAL")} {terminal}
                                            </div>
                                        ) : ""}
                                    </div>
                                    <div className="sponsor">
                                        <div className="update">{
                                            t("UPDATED")}
                                            <span className="status">
                                                {moment(last_updated_at).fromNow()}
                                            </span>
                                        </div>
                                        <div className="powered-by">{t("POWERED_BY_FLIGHT_STATS")}</div>
                                    </div>
                                </Tooltip>
                            }
                        >
                            <span className="flight">
                                <img src={PlaneGray} alt="plane" className="plane" />
                                {flightArrivalTime} {` + ${buffer_time} ${t("MIN_BUFFER")}`}
                            </span>
                        </OverlayTrigger>
                    )}
                    {rideStatus ? (
                        <div className="status">
                            <img src={ridIcons[rideStatus]} alt="ride status" className="icon" />
                            {t(`RIDE_${rideStatus}`)}
                        </div>
                    ) : ""}
                    {showMissedKpiAndScore ? (
                        <div className="performance">
                            <img src={cars[score]} className="car-icon" alt="car" />
                            {t(score.toUpperCase())}
                            <OverlayTrigger
                                placement="top"
                                key="missed-kpi-tooltip"
                                overlay={
                                    <Tooltip id="missed-kpi-tooltip">
                                        <h6 className="title">
                                            <img src={WarningRed} className="warning-image" alt="warning" />
                                            {t("MISSED_KPIS")}
                                        </h6>
                                        <ul className="kpi-list">
                                            {missed_kpis.map(kpi => (
                                                <li key={kpi}>{t(kpiTypes[kpi])}</li>
                                            ))}
                                        </ul>
                                    </Tooltip>
                                }
                            >
                                <div className="kpi">
                                    {missed_kpis.length} {t("MISSED_KPIS")}
                                </div>
                            </OverlayTrigger>
                        </div>
                    ) : ""}
                    <div>{t("FREE_WAITING_TIME")}: {get(row, 'free_waiting_time')} min</div>
                </div>
            )}
        </div>
    );
}

// * RIDE INFO
export function rideIfo({ row = {}, vehicle_type }) {
    const t = i18nInstance.t.bind(i18nInstance);
    return (
        <div className="reservation-details">
            <div className="reservation-id">{get(row, "reservation_id")}</div>
            {/* <div className="vehicle">{acriss_code}</div> */}
            <div className="class">{get(row, 'vehicle_acriss_type_info.title') || vehicle_type}</div>

            {get(row, 'is_preferred_tsp_applicable') && <div className="ic-ride"><img src={Passengers} alt="IC ride" className="icon" />{t('IC_RIDE')}</div>}
            {get(row, 'tags', [])?.includes("Hot Ride") && <div className="hot-ride">{t('HOT_RIDE')}</div>}
            {get(row, 'tags', [])?.includes("Fixed Price Ride") && <div className="hot-ride uppercase">{t('FIXED_PRICE_RIDE')}</div>}
            {get(row, 'is_vat_invoice_required_from_tsp') &&
                <OverlayTrigger
                    key="vat-not-required-tooltip"
                    placement="right"
                    overlay={
                        <Tooltip
                            id={`vat-not-required-tooltip`}
                            className='vat-invoice-tooltip'
                        >
                            {t('VAT_INVOICE_HELP_TEXT')}
                        </Tooltip>
                    }
                >
                    {/* <InfoIcon /> */}
                    <div className="hot-ride uppercase">{t('INVOICE_REQUIRED_TAG')}</div>
                </OverlayTrigger>
            }
        </div>
    );
}

// * PICK UP AND DROP
export function pickupAndDrop({ row = {} }) {
    const t = i18nInstance.t.bind(i18nInstance);
    const {
        flight_number = "",
        origin = {},
        destination = {},
        type = "",
        expected_distance = "",
        duration = 0
    } = row || {};

    const durationInHrs = duration >= 60 ? parseInt(duration) / 60 : (parseInt(duration) / 60).toFixed(1);

    const { name: originName = "", lat, lng } = origin || {};
    const { name: destName = "" } = destination || {};
    //flight_number.split(/([0-9]+)/).join("/")

    return (
        <div className="pickup-drop">
            {flight_number && (
                <a
                    href={`${getEnvData.FLIGHT_TRACKING_URL}${flight_number}`}
                    className="flight"
                    target="blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        sendGoogleAnalyticsEvent("flight", {
                            event_category: "manage_rides",
                            event_label: "tracking_link"
                        });
                    }}
                >
                    <img src={Plane} alt="plane" className="plane" />
                    {flight_number}
                </a>
            )}
            {originName ? (
                <div className="pickup">
                    <img src={PickUpDrop} alt="origin" className="icon" />
                    {originName}
                </div>
            ) : ""}
            {destName ? (
                <div className="drop">
                    <img src={PickUpDrop} alt="destination" className="icon" />
                    {destName}
                </div>
            ) : ""}
            {type === 'DISTANCE' && expected_distance
                && (
                    <div className="distance">
                        <a href={getMapDirectionsUrl(originName, destName, lat, lng)}
                            target="blank"
                            rel="noopener noreferrer"
                        >
                            <img src={Distance} alt="distance" className="icon" />
                            {`${(parseFloat(expected_distance) / 1000).toFixed(1)} Km`}
                            {duration
                                ? ` (${t("ESTIMATED_TIME")} ~${duration} mins)`
                                : ""
                            }
                        </a>
                    </div>
                )
            }
            {type === 'DURATION' && duration
                ? (
                    <div className="duration">
                        <a href={getMapDirectionsUrl(originName, destName)}
                            target="blank"
                            rel="noopener noreferrer"
                        >
                            <img src={Duration} alt="duration" className="icon" />
                            {`${durationInHrs} hrs`}
                        </a>
                    </div>
                ) : ""
            }
        </div>
    );
}

// * PAYOUT
export function payout({ row = {} }) {
    const { currency } = row;
    // condition for fixed price rides
    const rowDriverPrice = row?.driver_price_details?.pricing_strategy === 'frp' ? row.driver_price_details.driver_price : row.driver_price;
    let driver_price = rowDriverPrice ? parseFloat(rowDriverPrice / 100).toFixed(2) : 0;
    return (
        <div className="payout">
            {driver_price
                ? `${getCurrencySymbol(currency)} ${driver_price}`
                : `${getCurrencySymbol(currency)} 0`
            }
        </div>
    );
}

// * CUSTOMER NAME AND COMMENTS
export function customerNameAndComments({ row = {}, showCustomerName = true }) {
    const t = i18nInstance.t.bind(i18nInstance);
    const { comment = "", requisites = {}, travelling_customer = {} } = row;
    const { number_of_baggage = 0, child_seats = {}, number_of_passengers = 0 } = requisites || {};
    const { baby = 0, child = 0, booster = 0 } = child_seats || {};
    const totalSeats = parseInt(baby) + parseInt(child) + parseInt(booster);
    const { last_name = "", first_name = "" } = travelling_customer || {};

    const babyComponent = baby > 0 ? (
        <div className="list-item">
            <img src={Seats} alt="" className="icon" />
            <span className="label">{baby} {t("BABY_SEAT")}</span>
            <span className="details">(0 - 13 kg)</span>
        </div>) : "";

    const childComponent = child > 0 ? (<div className="list-item">
        <img src={Seats} alt="" className="icon" />
        <span className="label">{child} {t("CHILD_SEAT")}</span>
        <span className="details">(9 - 18 kg)</span>
    </div>) : "";

    const boosterComponent = booster > 0 ? (<div className="list-item">
        <img src={Seats} alt="" className="icon" />
        <span className="label">{booster} {t("BOOSTER_SEAT")}</span>
        <span className="details">(15 - 36 kg)</span>
    </div>) : "";

    return (
        <div className="customer-comments">
            {showCustomerName && (last_name || first_name)
                ? (<div className="customer-name">
                    <img src={PROFILE} alt="customer" className="icon" />
                    {`${first_name} ${last_name}`}
                </div>)
                : null
            }
            {comment
                ? (<div className="comment">
                    <img src={COMMENT} alt="comment" className="icon" />
                    {comment}
                </div>)
                : ""
            }
            {(totalSeats > 0 || number_of_baggage > 0 || number_of_passengers > 0) ? (
                <div className="other-details">
                    <div className="other-details-container">
                        {totalSeats > 0 && (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="requirements-tooltip-container">
                                        <h4>{t("ADDITIONAL_REQUIREMENTS")}</h4>
                                        <div className="list">
                                            {babyComponent}
                                            {childComponent}
                                            {boosterComponent}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <div className="detail-item">
                                    <img src={Seats} alt="seats" className="seats" />
                                    {totalSeats}
                                </div>
                            </OverlayTrigger>
                        )}
                        {number_of_baggage > 0 && (
                            <OverlayTrigger
                                placement="top"
                                key="luggage"
                                overlay={
                                    <Tooltip id="luggage">{t("NUMBER_OF_LUGGAGES")}</Tooltip>
                                }
                            >
                                <div className="detail-item">
                                    <img src={Suitcase} alt="luggage" className="luggage" />
                                    {number_of_baggage}
                                </div>
                            </OverlayTrigger>
                        )}
                        {number_of_passengers > 0 && (
                            <OverlayTrigger
                                placement="top"
                                key="passengers"
                                overlay={
                                    <Tooltip id="passengers">{t("NUMBER_OF_PASSENGERS")}</Tooltip>
                                }
                            >
                                <div className="detail-item">
                                    <img src={Passengers} alt="passengers" className="luggage" />
                                    {number_of_passengers}
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                </div>
            ) : ""
            }
        </div >
    );
}

// * DRIVER AND VEHICLE
export function driverAndVehicle({ row = {}, drivers, showVehicle = true, licensePlate = "" }) {
    const t = i18nInstance.t.bind(i18nInstance);
    const { driver_id, vehicle } = row;
    const {
        first_name = "",
        last_name = ""
    } = drivers.find(driver => driver.driver_id === driver_id) || {};

    return (
        <div className="driver-vehicle">
            {(first_name || last_name) ? (
                <div className="driver">
                    <img src={DriverGrayIcon} alt="Driver" className="icon" />
                    {`${first_name} ${last_name}`}
                </div>
            ) : ""}
            {showVehicle && (get(vehicle, "license_number", "") || licensePlate) ? (
                <div className="vehicle">
                    <img src={VehicleGrayIcon} alt="Vehicle" className="icon" />
                    {get(vehicle, "license_number") || licensePlate}
                </div>
            ) : ""}
            {(first_name || last_name || get(vehicle, "license_number", "") || licensePlate)
                ? "" : (
                    <div className="unassigned">
                        {t("UNASSIGNED")}
                    </div>
                )}
        </div>
    );
}

// * START RATING
export function rating({ row = {} }) {
    const { number_of_rating = 0, total_rating = 0 } = row || {};
    const ratings = (total_rating > 0 && number_of_rating >= 3
        && (total_rating / number_of_rating <= 5))
        ? parseFloat(total_rating / number_of_rating).toFixed(1)
        : null;

    return ratings ? (
        <>
            <div className="stars" style={{ "--rating": (Math.floor(ratings * 2) / 2).toFixed(1) }} />
            <span className="rating-number">({ratings})</span>
        </>
    ) : null;
}

// * RIDE PERFORMANCE
export function ridePerformance({ cell }) {
    const t = i18nInstance.t.bind(i18nInstance);
    const cars = {
        "PERFECT": GreenCar,
        "AVERAGE": OrangeCar,
        "POOR": RedCar,
    };
    return cell && (
        <div className="ride-performance-cell">
            <img src={cars[cell]} className="car-icon" alt="car" />
            {t(cell.toUpperCase())}
        </div>
    );
}

// * MISSED KPI'S
export function missedKpis({ cell = [] }) {
    const t = i18nInstance.t.bind(i18nInstance);

    const kpiTypes = {
        "ride_confirmation_card_pressed": "RIDE_NOT_CONFIRMED_ON_TIME",
        "pre_ride_tracking": "APP_NOT_KEPT_OPEN_ENROUTE_PICKUP",
        "arrived_within_pickup": "NOT_ARRIVED_ON_TIME_AT_PICKUP",
        "arrived_outside_pickup": "NOT_ARRIVED_ON_TIME_AT_PICKUP",
        "on_ride_tracking": "APP_NOT_KEPT_OPEN_DURING_RIDE",
        "ride_completed_on_time": "RIDE_NOT_COMPLETED_ON_TIME"
    };

    return <>
        {cell.length > 0 ? (
            <div className="missed-kpi-cell">
                <OverlayTrigger
                    placement="top"
                    key="missed-kpi-tooltip"
                    overlay={
                        <Tooltip id="missed-kpi-tooltip">
                            <h6 className="title">
                                <img src={WarningRed} className="warning-image" alt="warning" />
                                {t("MISSED_KPIS")}
                            </h6>
                            <ul className="kpi-list">
                                {cell.map(kpi => (
                                    <li key={kpi}>{t(kpiTypes[kpi])}</li>
                                ))}
                            </ul>
                        </Tooltip>
                    }
                >
                    <span>{cell.length}</span>
                </OverlayTrigger>
            </div>
        ) : "-"}
    </>;
}

// * Perfect/average/poor ride count,
export function count({ cell }) {
    return cell || 0;
}

// * simple text
export function plainText(text) {
    return text;
}

// * -----------------  Table sorting functions -----------------------

export function sortByNumber(a, b, order) {
    if (order === 'asc') {
        return b - a;
    }
    return a - b; // desc
}

export function sortByFirstLastName(a, b, order, drivers) {

    const getFullName = memoize(driver_id => {
        const {
            first_name = "",
            last_name = ""
        } = drivers.find(driver => driver.driver_id === driver_id) || {};

        return first_name + last_name;
    });

    const aName = getFullName(a);
    const bName = getFullName(b);

    if (aName === bName) {
        return 0;
    }
    if (aName === "") {
        return 1;
    }
    if (bName === "") {
        return -1;
    }
    if (order === 'asc') {
        return bName > aName ? 1 : -1;
    }
    return bName > aName ? -1 : 1; // desc
}

export function showColumn(condition, column) {
    return condition ? [column] : [];
}

export function sortByDate(a, b, order) {
    const date1 = moment(a).valueOf();
    const date2 = moment(b).valueOf();
    if (order === 'asc') {
        return date2 - date1;
    }
    return date1 - date2; // desc
}